import React, { useContext, useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Trans } from "@lingui/macro";
import { styled, media, angle, pink } from "../styles";
import { Page, Content, Link } from "../components";
import { awardHost } from "../utils";
import RocketImg from "../views/HomePage/assets/Rocket_ani.inline.svg";
import BlitzImg from "../views/HomePage/assets/Blitz_ani.inline.svg";
import ExplosionImg from "../views/HomePage/assets/Explosion_ani.inline.svg";

const Awards: React.FC<{}> = props => {
  const [awardData, setAwardData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    //console.log("use effect");
    const loadAwards = async () => {
      try {
        const response = await fetch(awardHost + "/awards.php");
        const jsonData = await response.json();
        //console.log(jsonData);
        return jsonData;
      } catch (err) {
        console.log(err);
      }
    };
    loadAwards().then(data => {
      setAwardData(data);
      setShowLoader(false);
    });
  }, []);

  const {
    paperImg: {
      image: {
        fixed: { src: paperImg }
      }
    },
    cactusImg: {
      image: {
        fixed: { src: cactusImg }
      }
    },
    pinImg: {
      image: {
        fixed: { src: pinImg }
      }
    },
    birdImg: {
      image: {
        fixed: { src: birdImg }
      }
    }
  }: {
    paperImg: {
      image: {
        fixed: { src: string };
      };
    };
    cactusImg: {
      image: {
        fixed: { src: string };
      };
    };
    pinImg: {
      image: {
        fixed: { src: string };
      };
    };
    birdImg: {
      image: {
        fixed: { src: string };
      };
    };
  } = useStaticQuery(graphql`
    query {
      paperImg: file(relativePath: { eq: "home/bg_paper.png" }) {
        image: childImageSharp {
          fixed(width: 1000, quality: 90) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      cactusImg: file(relativePath: { eq: "home/lego_kaktus.png" }) {
        image: childImageSharp {
          fixed(quality: 90) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      pinImg: file(relativePath: { eq: "home/pin.png" }) {
        image: childImageSharp {
          fixed(quality: 90) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      birdImg: file(relativePath: { eq: "home/pink_bird.png" }) {
        image: childImageSharp {
          fixed(quality: 90) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  interface AwardItem {
    award: string;
    platzierung: string;
    kategorie: string;
    projekt: string;
    jahr: string;
    id: string;
  }

  // const awardData_ = [
  //   {
  //     award: "ADC Europe",
  //     platzierung: "Bronze",
  //     kategorie: "Audio / Radio Commercials",
  //     projekt: "Terre des Femmes",
  //     jahr: "2022",
  //     id: "0"
  //   }
  // ];

  return (
    <Page
      title="Awards"
      seoTitle="Tonstudio | Musik, Sounddesign & Sprachaufnahmen | German Wahnsinn"
      description="Wir komponieren und vertonen für euch und für alle Werbeagenturen, Filmproduktionen, Verlage, Label und Musiker."
      keywords={[
        "German Wahnsinn",
        "Tonstudio",
        "Musikstudio",
        "Sounddesign",
        "Sprachaufnahmen",
        "Audio",
        "Postproduction",
        "Hörbücher",
        "Hörspiele",
        "Podcasts"
      ]}
      sideLabel={false}
      bgPaper="square"
      {...props}
    >
      <Content>
        <HomeContainer id="awards">
          <HomeHeading>
            <Trans>Awards</Trans>
          </HomeHeading>

          <Explosion alt="Explosion" />

          <Paper bg={paperImg}>
            <Pin src={pinImg} alt="Pin" />

            <Blitz alt="Blitz" />

            <Bird src={birdImg} alt="Pink bird" />
            {showLoader ? (
              <LoaderContainer>
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </LoaderContainer>
            ) : (
              <AwardsTable>
                <thead>
                  <tr>
                    <th>Award</th>
                    <th>Platzierung</th>
                    <th>Kategorie</th>
                    <th>Projekt</th>
                    <th>Jahr</th>
                  </tr>
                </thead>
                <tbody>
                  {awardData
                    ? awardData.map((item: AwardItem, idx) => (
                        <tr key={idx}>
                          <td>{item.award}</td>
                          <td>{item.platzierung}</td>
                          <td>{item.kategorie}</td>
                          <td>{item.projekt}</td>
                          <td>{item.jahr}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </AwardsTable>
            )}
          </Paper>
        </HomeContainer>

        <Rocket alt="Rocket" />

        <Cactus alt="Cactus" src={cactusImg} />
      </Content>
    </Page>
  );
};

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  a {
    @media not all and (hover: hover) {
      color: ${pink};

      &:hover {
        /* animation: none; */
      }
    }

    @media not all and (hover: none) {
      color: inherit;

      &:hover {
        color: ${pink};
      }
    }
  }

  ${media.laptop} {
    width: 83.3%;
  }
`;

const Paper = styled.div<{ bg: string }>`
  background-image: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  z-index: 1;

  padding: 0;
  margin: 0 auto;

  ${media.tablet} {
    padding: 10% 8% 20rem;
    margin: 0 auto;
  }

  ${media.laptop} {
    background-image: ${({ bg }) => `url(${bg})`};
    padding: 10% 18% 20rem 5rem;
    margin: 2rem 0 auto auto;
    ${angle(-2)};
  }

  ${media.desktop} {
    margin: 5rem 0 auto auto;
    padding: 10% 18% 20rem 10%;
  }
`;

const HomeHeading = styled.h1`
  z-index: 1;
  margin-bottom: 2rem;
  margin-left: 0;
  padding-top: 2rem;
  font-size: 37px;
  text-align: center;

  span::before {
    content: "\\A";
    white-space: pre;
  }

  ${media.tablet} {
    margin-bottom: 0;
    margin-left: 3.5rem;
    padding-top: 0;
    font-size: 67px;
    text-align: left;
    ${angle(-4)};
  }

  ${media.laptop} {
    font-size: 72px;
    margin-left: 2.5rem;
  }

  ${media.desktop} {
    margin-left: 0;
  }
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AwardsTable = styled.table`
  width: 100%;
  display: block;

  th {
    text-align: left;
  }

  ${media.smalltables} {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
      font-size: 1.2rem;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      margin: 0 0 1rem 0;
    }

    tr:nth-child(odd) {
      background: rgba(255, 0, 100, 0.2);
    }

    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      padding-bottom: 10px;
      padding-top: 10px;
    }

    td:before {
      position: absolute;
      top: 10px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }

    td:nth-of-type(1):before {
      content: "Award";
      font-weight: bold;
    }
    td:nth-of-type(2):before {
      content: "Platzierung";
      font-weight: bold;
    }
    td:nth-of-type(3):before {
      content: "Kategorie";
      font-weight: bold;
    }
    td:nth-of-type(4):before {
      content: "Projekt";
      font-weight: bold;
    }
    td:nth-of-type(5):before {
      content: "Jahr";
      font-weight: bold;
    }
  }
`;

const Explosion = styled(ExplosionImg)`
  position: absolute;
  top: -1%;
  right: 5%;
  width: 40%;
  max-width: 150px;
  z-index: 0;

  ${media.tablet} {
    max-width: unset;
    width: 30%;
    top: -5%;
    right: 15%;
  }

  ${media.laptop} {
    width: 20%;
    top: -1%;
  }
`;

const Blitz = styled(BlitzImg)`
  position: absolute;
  display: none;
  z-index: 2;

  ${media.laptop} {
    display: block;
    top: 3%;
    right: 13%;
    width: 60px;
    ${angle(16)};
  }
`;

const Rocket = styled(RocketImg)`
  position: relative;
  z-index: 2;
  width: 50%;
  max-width: 200px;
  margin: 0 25%;

  ${media.tablet} {
    display: block;
    width: 30%;
    max-width: unset;
    position: absolute;
    bottom: 5%;
    left: 15%;
    margin: unset;
  }

  ${media.laptop} {
    width: 20%;
    bottom: 3%;
  }
`;

const Pin = styled.img`
  position: absolute;
  display: none;
  z-index: 2;
  ${angle(-15)};

  ${media.laptop} {
    display: block;
    width: 25%;
    top: 25%;
    right: -2%;
  }

  ${media.desktop} {
    width: 20%;
    right: 2%;
  }
`;

const Bird = styled.img`
  display: none;

  ${media.laptop} {
    display: block;
    position: absolute;
    top: 35%;
    left: -25%;
    width: 238px;
    z-index: 2;
    ${angle(22)};
  }
`;

const Cactus = styled.img`
  position: absolute;
  display: none;
  z-index: 2;
  left: 48%;

  ${media.tablet} {
    display: block;
    width: 30%;
    bottom: 5%;
  }

  ${media.laptop} {
    width: 25%;
    bottom: -6%;
  }

  ${media.desktop} {
    width: 20%;
  }
`;

export default Awards;
